import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import langAtom from 'stores/langAtom';
import { getSystemLang } from 'utils/getSystemLang';

export const LangBanner = ({
  pathname,
  langComb,
  showBanner,
  setShowBanner,
}: {
  pathname: string;
  langComb: string;
  showBanner: boolean;
  setShowBanner: Dispatch<SetStateAction<boolean>>;
}) => {
  /* ============================== 0. Variables ============================== */
  // --------------------------------------------------------------------------
  // Atom
  // --------------------------------------------------------------------------
  const [, setLang] = useAtom(langAtom);
  // --------------------------------------------------------------------------
  // State
  // --------------------------------------------------------------------------
  const [browserLang, setBrowserLang] = useState('en');
  const [selectLang, setSelectLang] = useState('en');
  const [isDropdown, setIsDropdown] = useState(false);
  // --------------------------------------------------------------------------
  // Ref
  // --------------------------------------------------------------------------
  const selectRef = useRef<HTMLButtonElement | null>(null);
  const dropDownRef = useRef<HTMLDivElement | null>(null);
  // --------------------------------------------------------------------------
  // Etc
  // --------------------------------------------------------------------------
  const router = useRouter();
  const { t } = useTranslation('headerFooterMenu');
  const cookies = new Cookies();

  const langBannerDescText = t('LangBanner.desc', { lng: browserLang });
  const langBannerBtnText = t('LangBanner.button', { lng: browserLang });

  /* ============================== 0. Variables ============================== */

  const options = [
    { value: 'ko', label: '한국어' },
    { value: 'en', label: 'English' },
    { value: 'ja', label: '日本語' },
    { value: 'cn', label: '简体中文' },
    { value: 'es', label: 'Español' },
  ];

  // '' in case of en, /ko, /ja, /cn in case of those languages
  const langPath = selectLang === 'en' ? '' : `/${selectLang}`;
  // '' if pathname is /, /cn, /ja, /ko
  // /grammar-check if pathname is /grammar-check, /cn/grammar-check
  const defaultPath = pathname.replace(/\/(cn|ko|ja|es)/, '') === '/'
    ? ''
    : pathname.replace(/\/(cn|ko|ja|es)/, '');

  const changedLangLink = `${langPath}${defaultPath}` || '/';

  const currSelectLang = options.filter(item => item.value === selectLang)?.[0]?.label;
  const selectArr = options.filter(item => item.value !== selectLang);

  const descFont = browserLang === 'cn' ? '!font-cnSans' : 'font-sans';
  const selectBtnBtmRadius = !isDropdown && 'rounded-b-sm md:rounded-b-md lg:rounded-b-lg';

  const handleClose = () => {
    setShowBanner(false);
    cookies.set('no-lang-banner', true, { path: '/', maxAge: Number(process.env.COOKIE_MAX_AGE) });
  };

  useEffect(() => {
    const acceptLangArr = window.navigator.languages.map(item => item.substring(0, 2));
    const getBrowserLang = getSystemLang(acceptLangArr) || 'en';
    if (typeof window !== 'undefined' && langComb !== getBrowserLang) {
      setBrowserLang(getBrowserLang);
      setSelectLang(getBrowserLang);
    }
  }, []);

  useEffect(() => {
    const closeSelect = (e: MouseEvent): void => {
      if (
        dropDownRef.current
        && selectRef.current
        && !dropDownRef.current.contains(e.target as Node)
        && !selectRef.current.contains(e.target as Node)
      ) {
        setIsDropdown(false);
      }
    };
    document.addEventListener('mousedown', closeSelect);
    return () => {
      document.removeEventListener('mousedown', closeSelect);
    };
  }, [dropDownRef]);

  return (
    showBanner ? (
      <div
        className={`w-min-[360px] py-[16px] flex flex-col justify-center
        items-center border-b border-primary bg-primaryLow
        md:w-full md:h-min-[64px] md:px-[20px] md:py-[14px] md:flex-row
        md:justify-between lg:justify-center lg:py-[20px]`}
      >
        {/* =========================================================== */}
        {/* text + x button(S_only) */}
        <div
          className={`w-[312px] flex justify-between items-center
          md:w-fit lg:mr-[24px]`}
      >
          {/* Text */}
          <div
            dangerouslySetInnerHTML={{ __html: langBannerDescText }}
            className={`text-black text-subheaderXS
            md:text-subheaderS lg:text-subheaderM ${descFont}`}
        />
          {/* Close Icon - S_only */}
          <img
            alt="line icon of x"
            src="/icons/outline/i_close.svg"
            className="S_only w-[24px] h-[24px] cursor-pointer"
            onClick={handleClose}
          />
        </div>
        {/* =========================================================== */}
        {/* =========================================================== */}
        {/* select button + link button + x button(ML_only) */}
        <div
          className="w-[312px] mt-[16px] flex justify-between
          text-buttonM1 lg:text-buttonL1
          md:mt-0 md:w-fit"
        >
          {/* Lang Select */}
          <button
            type="button"
            ref={selectRef}
            onClick={() => setIsDropdown(!isDropdown)}
            className={`w-[220px] h-[32px] px-[8px] flex justify-between
            items-center text-darkGray bg-surface
            border border-darkGray ${selectBtnBtmRadius} rounded-t-sm
            md:w-[120px] md:h-[40px] md:mr-[12px] md:rounded-t-md
            lg:w-[136px] lg:mr-[16px] lg:rounded-t-lg`}
          >
            <img
              alt="line icon of the globe"
              src="/icons/outline/i_globe_darkgray.svg"
              className="w-[20px] h-[20px]"
          />
            {currSelectLang}
            <img
              alt={`line icon of arrow pointing to the ${isDropdown ? 'top' : 'bottom'}`}
              src={isDropdown ? '/icons/outline/i_up_darkgray.svg' : '/icons/outline/i_down_darkgray.svg'}
              className="w-[24px] h-[24px]"
          />
          </button>
          {/* Select Dropdown */}
          {isDropdown && (
          <div
            ref={dropDownRef}
            className={`w-[220px] absolute mt-[31px] flex flex-col rounded-b-sm
              bg-surface border border-darkGray overflow-hidden
              md:w-[120px] md:mt-[39px] md:rounded-b-md
              lg:w-[136px] lg:rounded-b-lg`}
          >
            {selectArr.map(item => (
              <button
                type="button"
                key={item.value}
                className={`h-[32px] flex justify-center items-center border-b
                  border-darkGray hover:bg-primary hover:text-surface
                  last-of-type:border-0 md:h-[40px]`}
                onClick={() => {
                  setSelectLang(item.value);
                  setIsDropdown(false);
                }}
              >
                {item.label}
              </button>
            ))}
          </div>
          )}
          {/* Continue button */}
          <button
            type="button"
            className={`w-[80px] h-[32px] flex items-center justify-center
              text-buttonM2 text-surface bg-primary rounded
              disabled:text-disabled disabled:bg-containerDisabled
              md:w-[120px] md:h-[40px] md:mr-[12px] md:rounded-md
              lg:w-[136px] lg:mr-[16px] lg:text-buttonL2 lg:rounded-lg`}
            onClick={() => {
              setLang(selectLang);
              if (langComb === selectLang) {
                setShowBanner(false);
              }
              router.push(changedLangLink);
            }}
          >
            {langBannerBtnText}
          </button>
          <img
            alt="line icon of x"
            src="/icons/outline/i_close.svg"
            className="ML_only w-[24px] h-[24px] mt-[8px] cursor-pointer"
            onClick={handleClose}
          />
        </div>
      </div>
    ) : null
  );
};
