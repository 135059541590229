import { Dispatch, SetStateAction, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useAtom } from 'jotai';
import langAtom from 'stores/langAtom';
import { useTranslation } from 'react-i18next';
import { Logo } from 'components/images';
import { getLangPath } from 'utils/getLangPath';
import styles from './header-footer.module.css';
import { LangBanner } from './LangBanner';

export function LandingHeader({
  pathLang,
  showBanner,
  setShowBanner,
}: {
  pathLang?: string;
  showBanner: boolean;
  setShowBanner: Dispatch<SetStateAction<boolean>>;
}) {
  /* ============================== 0. Variables ============================== */
  // --------------------------------------------------------------------------
  // Atom
  // --------------------------------------------------------------------------
  const [lang] = useAtom(langAtom);
  // --------------------------------------------------------------------------
  // State
  // --------------------------------------------------------------------------
  // showing folded menu in mobile header
  const [showMenu, setShowMenu] = useState(false);
  const [showOtherProducts, setShowOtherProducts] = useState(false);
  // --------------------------------------------------------------------------
  const { t } = useTranslation('headerFooterMenu');
  const router = useRouter();
  const langComb = pathLang || lang;

  const landingLink = getLangPath('/', langComb);
  const signInBtnText = t('LandingHeader.Sign in', { lng: langComb });

  const correctionTrialText = t('LandingHeader.Grammar', { lng: langComb });
  const correctionTrialLink = getLangPath('/grammar-check', langComb);

  const paraTrialText = t('LandingHeader.Paraphrase', { lng: langComb });
  const paraTrialLink = getLangPath('/paraphrase', langComb);

  const translateTrialText = t('LandingHeader.Translate', { lng: langComb });
  const translateTrialLink = getLangPath('/translate', langComb);

  const resumeText = t('LandingHeader.Resume', { lng: langComb });
  const resumeLink = getLangPath('/resume-builder', langComb);

  const wordCounterText = t('LandingHeader.Word counter', { lng: langComb });
  const wordCounterLink = getLangPath('/word-counter', langComb);

  const pricingText = t('LandingHeader.Pricing', { lng: langComb });
  const pricingLink = getLangPath('/pricing', langComb);

  const otherProductText = t('LandingHeader.Other products', { lng: langComb });

  // used in middle part of the landing header
  const fullMenuList = [
    {
      name: correctionTrialText,
      link: correctionTrialLink,
    },
    {
      name: paraTrialText,
      link: paraTrialLink,
    },
    {
      name: translateTrialText,
      link: translateTrialLink,
    },
    {
      name: resumeText,
      link: resumeLink,
    },
    {
      name: wordCounterText,
      link: wordCounterLink,
    },
    {
      name: pricingText,
      link: pricingLink,
    },
  ];
  /* ======================================================================== */
  // --------------------------------------------------------------------------
  // Style
  // --------------------------------------------------------------------------
  const navItemCommonStyle = `btn0 btn-text h-[40px] text-gray
  md:text-buttonM1 ${langComb === 'es' ? 'md:px-[12px]' : 'md:px-[16px]'}
  lg:text-buttonL1 lg:px-[24px]`;

  const navItemActivatedStyle = (comparisonPathName: string) => {
    return comparisonPathName === router.pathname ? 'text-primary font-bold' : '';
  };

  const signInBtnStyle = `w-[80px] h-[32px]
  md:w-[84px] md:h-[40px] lg:w-[120px]`;

  const headerInnerCommonStyle = `w-[312px] max-w-1232 h-[48px] mx-auto my-0
        justify-between items-center bg-background
        md:w-full md:h-[64px] md:mx-[24px] lg:h-[64px] lg:mx-auto`;

  const restyledLinkStyle = 'flex justify-center items-center';

  let moreBtnWidth;
  switch (langComb) {
    case 'es':
      moreBtnWidth = 'md:w-[88px] lg:w-[136px]';
      break;
    case 'ko':
      moreBtnWidth = 'md:w-[88px] lg:w-[108px]';
      break;
    case 'ja':
      moreBtnWidth = 'md:w-[111px] lg:w-[138px]';
      break;
    case 'cn':
      moreBtnWidth = 'md:w-[88px] lg:w-[112px]';
      break;
    default:
      // en case
      moreBtnWidth = 'lg:w-[129px] md:w-[67px]';
      break;
  }

  return (
    // Header Wrapper
    <header className="w-full w-min-[360px] fixed left-0 top-0 right-0 z-[100]">
      {/* Language Banner */}
      <LangBanner
        pathname={router.pathname}
        langComb={langComb}
        showBanner={showBanner}
        setShowBanner={setShowBanner}
      />
      <header
        className="w-full min-w-[360px] flex items-center bg-background shadow-bar"
      >
        {/* 0. Landing Header that is shown on L&M screen */}
        {/* headerInner */}
        <div className={`${headerInnerCommonStyle} ML_only flex items-center`}>
          {/* Logo */}
          <Logo href={landingLink} className="w-[92px] lg:w-[110px]" />
          {/* Navigation at the center */}
          <div className="flex flex-row">
            {/* correction trial */}
            <div className={`${navItemCommonStyle} ${navItemActivatedStyle(correctionTrialLink)}`}>
              <Link href={correctionTrialLink} className={restyledLinkStyle}>
                {correctionTrialText}
              </Link>
            </div>
            {/* paraphrase trial */}
            <div className={`${navItemCommonStyle} ${navItemActivatedStyle(paraTrialLink)}`}>
              <Link href={paraTrialLink} className={restyledLinkStyle}>
                {paraTrialText}
              </Link>
            </div>
            {/* translate */}
            <div className={`${navItemCommonStyle} ${navItemActivatedStyle(translateTrialLink)}`}>
              <Link href={translateTrialLink} className={restyledLinkStyle}>
                {translateTrialText}
              </Link>
            </div>
            {/* other products */}
            <div
              className={`${navItemCommonStyle} ${showOtherProducts && 'bg-primaryLow rounded-tl-lg rounded-tr-lg'}
              ${moreBtnWidth} px-0
              `}
              onMouseEnter={() => setShowOtherProducts(true)}
              onMouseLeave={() => setShowOtherProducts(false)}
            >
              <span dangerouslySetInnerHTML={{ __html: otherProductText }} />
              {showOtherProducts && (
              <div
                className={`flex flex-col absolute lg:mt-[120px] bg-primaryLow rounded-bl-lg rounded-br-lg
                  ${langComb === 'en' || langComb === 'es' ? 'md:mt-[136px]' : 'md:mt-[103px]'}
                  ${moreBtnWidth}
                `}
              >
                <Link
                  href={resumeLink}
                  className={`w-full lg:h-[40px] flex justify-center items-center
                    text-bodyS leading-normal hover:text-primary
                    ${langComb === 'en' || langComb === 'es' ? 'md:h-[48px]' : 'md:h-[32px]'}
                  `}
                  dangerouslySetInnerHTML={{ __html: resumeText }}
                />
                <Link
                  href={wordCounterLink}
                  className={`w-full lg:h-[40px] flex justify-center items-center
                    text-bodyS leading-normal hover:text-primary
                    ${langComb === 'en' || langComb === 'es' ? 'md:h-[48px]' : 'md:h-[32px]'}
                  `}
                  dangerouslySetInnerHTML={{ __html: wordCounterText }}
                />
              </div>
              )}
            </div>
            {/* pricing */}
            <div
              className={`${navItemCommonStyle} ${navItemActivatedStyle(pricingLink)}`}
            >
              <Link href={pricingLink}>
                {pricingText}
              </Link>
            </div>
          </div>
          {/* Sign in button */}
          <Link href="/auth/sign-in" className={`btn0 btn-line ${signInBtnStyle}`}>
            {signInBtnText}
          </Link>
        </div>
        {/* landing header that is shown on mobile screen, contains hamburger menu */}
        {/* headerInner */}
        <div className={`${headerInnerCommonStyle} S_only flex`}>
          <img
            onClick={() => setShowMenu(true)}
            alt="hamburger menu icon"
            src="/icons/outline/i_menu.svg"
            className="w-[24px] h-[24px] cursor-pointer"
        />
          <Link href="/auth/sign-in" className={`btn0 btn-line ${signInBtnStyle}`}>
            {signInBtnText}
          </Link>
          {/* Menu that slides in and out */}
          <div
            className={`w-full fixed flex flex-col top-0 bottom-0 right-0 left-0
              px-[24px] ${styles['mb-menu-p-bottom']} bg-background
              ${styles['header-transition']}
              ${showMenu ? 'translate-x-0' : 'translate-x-full'}`}
          >
            <div className="w-full h-[48px] flex justify-end items-center">
              <img
                alt={t('landingHeader.Close', { lng: langComb })}
                onClick={() => setShowMenu(false)}
                src="/icons/outline/i_close.svg"
                className="w-[24px] h-[24px] cursor-pointer"
              />
            </div>
            {fullMenuList.map(item => (
              <Link
                key={item.name}
                href={item.link}
                dangerouslySetInnerHTML={{ __html: item.name }}
                className={`h-[48px] flex items-center text-bodyM font-medium
                cursor-pointer ${item.link === router.pathname ? 'text-primary' : 'text-darkGray'}`}
              />
            ))}
          </div>
        </div>
      </header>
    </header>
  );
}

export default LandingHeader;
