import React from 'react';
import { useRouter } from 'next/router';
import { Footer, Header } from 'organisms';
import { ToastContainer } from 'react-toastify';

type Props = {
  children?: React.ReactNode;
  page?: string;
  isRealTime?: boolean;
  isResume?: boolean;
  isIosLessThan16?: boolean;
  isMb?: boolean;
};

export const MainLayout = ({ children, page, isRealTime, isResume, isIosLessThan16, isMb, ...props }: Props) => {
  const router = useRouter();
  const { pathname }: { pathname: string } = router;

  const isProductPage = pathname === '/proofread'
    || pathname === '/proofread-mb'
    || pathname === '/paraphrasing'
    || pathname === '/paraphrasing-mb'
    || pathname === '/translation';

  // the following CSS property is set to avoid text input area
  // being shadowed by header in Resume page
  // https://github.com/pcanpi/web/issues/1452
  let containerStyle;
  if (isMb) {
    containerStyle = 'w-screen fixed';
  } else {
    containerStyle = `relative
    ${isResume && 'max-h-screen overflow-auto snap-mandatory snap-y scroll-pt-48 md:scroll-pt-64'}
    `;
  }

  return (
    <div className={`min-h-screen bg-background ${containerStyle}`} {...props}>
      <Header correctPage={isProductPage} isRealTime={isRealTime} isIosLessThan16={isIosLessThan16} />
      {/* padding is same amount as the header height. */}
      <main
        className={`min-h-[calc(100vh-275px)] pt-48
        ${page === 'about' && 'flex'}
        md:pt-[64px] lg:min-h-screen
        `}
      >
        {children}
      </main>
      {!isProductPage && <Footer />}
      {/* copy toast container */}
      <ToastContainer
        enableMultiContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeButton={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId="sug-copy"
        toastClassName="toast-class"
        bodyClassName="toast-body-class text-black"
      />
      <ToastContainer
        enableMultiContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeButton={false}
        closeOnClick
        rtl={false}
        draggable
        containerId="error-light"
        toastClassName="toast-class !bg-errorContainerLight"
        bodyClassName="toast-body-class text-error"
      />
      {/* w-fit was only used for proof. not para. */}
      <ToastContainer
        enableMultiContainer
        closeButton={false}
        containerId="words"
        progressClassName="toastProgress"
        toastClassName="toast-class w-fit !bg-errorContainerLight"
        bodyClassName="toast-body-class text-error"
      />
    </div>
  );
};
